.enterprises {
  margin-bottom: 259px;

  &-top,
  &-results {
    margin-inline: auto;
    max-width: 1144px;
    width: 100%;
  }

  &-hero {
    margin-bottom: 90px;
    
    .container-absolute {
      .hero-illustration {
        img {
          bottom: -3px;
          opacity: 0.23;
          right: -7px;
        }
      }
    }
  }

  &-top {
    margin-bottom: 34px;

    &-filters {
      border-bottom: 1px solid #D9D9D9;
      margin-bottom: 26px;
      padding-bottom: 26px;

      h2 {
        color: #1F1F1F;
        font-size: 2.2rem;
        font-weight: 400;
        line-height: 2.8rem;
        margin-bottom: 12px;
      }

      nav {
        ul {
          display: flex;
          overflow-y: hidden;
          overflow-x: auto;
          margin-bottom: -10px;
          padding-bottom: 10px;

          &::-webkit-scrollbar {
            height: 3px;
            width: 3px;
          }
          
          &::-webkit-scrollbar-track {
            background-color: #eee;
          }
          
          &::-webkit-scrollbar-thumb {
            background-color: #cccc;
          }

          li {
            & + li {
              margin-left: 14px;
            }

            a {
              background-color: #eaeaea;
              border-radius: 2px;
              color: #515151;
              font-size: 1.4rem;
              font-weight: 500;
              height: 43px;
              letter-spacing: 0.28px;
              line-height: 2rem;
              min-width: max-content;
              padding: 12px 14px;
              text-transform: uppercase;
              transition: 0.3s background-color;

              &:not(.active) {
                @include hover() {
                  &:hover {
                    background-color: #e3e3e3;
                  }
                }
              }

              &.active {
                background-color: #902125;
                color: #fff;
                font-weight: 400;
                pointer-events: none;
                touch-action: none;
              }
            }
          }
        }
      }
    }

    &-results {
      p {
        color: #515151;
      }
    }
  }

  &-results {
    position: relative;

    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 56px 32px;
    }

    .swiper-pagination {
      justify-content: center;
      margin-top: 32px;
      width: 100%;
    }
  }
}

@include responsive(1279) {
  .enterprises {
    &-top,
    &-results {
      margin-inline: auto;
      max-width: 790px;
    }

    &-hero {
      .container-absolute {
        .hero-illustration {
          img {
            opacity: .13;
            right: -8px;
          }
        }
      }
    }

    &-results {
      &-grid {
        gap: 56px 32px;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}


@include responsive(767) {
  .enterprises {
    margin-bottom: 310px;

    &-results {
      &-grid {
        gap: 46px 16px;
      }

      .enterprises-item {
        &-benefits {
          flex-direction: column;
          align-items: flex-start;

          li {
            max-width: 100% !important;

            & + li {
              margin-left: 0;
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
}

@include responsive(599) {
  .enterprises {
    &-hero {
      .container-absolute {
        .hero-illustration {
          img {
            right: -4px;
          }
        }
      }
    }

    &-top {
      max-width: 100%;
      overflow: hidden;
    }

    &-results {
      max-width: 348px;

      &-grid {
        gap: 56px 32px;
        grid-template-columns: 1fr;
      }
    }
  }
}