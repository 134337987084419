.loader {
  aspect-ratio: 1;
  animation: loaderBtn 1s infinite linear;
  border: 3px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  width: 22px;

  &-accent {
    border-top-color: #902125;
    border-left-color: #902125;
    border-bottom-color: #902125;
  }
}