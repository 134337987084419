.box-title {
  .sub-title {
    align-items: center;
    color: #323232;
    display: inline-flex;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.1rem;
    margin-bottom: 4px;
    text-transform: uppercase;

    svg {
      margin-right: 10px;
    }
  }

  .title {
    color: #323232;
  }

  p {
    margin-top: 12px;
  }
}