h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1F1F1F;
  font-family: $secondary-font;
  font-weight: 300;
}

h1:not(.no-config),
.title-h1 {
  font-size: 6rem;
  font-weight: 500;
  line-height: 6.4rem;
}

h2:not(.no-config),
.title-h2 {
  font-size: 4.4rem;
  line-height: 5rem;
}

h3:not(.no-config),
.title-h3 {
  font-size: 2.8rem;
  line-height: 3rem;
}

@include responsive(1279) {
  h1:not(.no-config),
  .title-h1 {
    font-size: 5.8rem;
    line-height: 6rem;
  }
}

@include responsive(991) {
  h1:not(.no-config),
  .title-h1 {
    font-size: 5.4rem;
    line-height: 5.9rem;
  }

  h2:not(.no-config),
  .title-h2 {
    font-size: 4rem;
    line-height: 4.8rem;
  }
}

@include responsive(767) {
  h1:not(.no-config),
  .title-h1 {
    font-size: 4.8rem;
    line-height: 5.8rem;
  }

  h2:not(.no-config),
  .title-h2 {
    font-size: 3.4rem;
    line-height: 4.2rem;
  }
}

@include responsive(599) {
  h1:not(.no-config),
  .title-h1 {
    font-size: 4.4rem;
    line-height: 5.3rem;
  }

  h2:not(.no-config),
  .title-h2 {
    font-size: 3rem;
    line-height: 3.8rem;
  }
}

@include responsive(479) {
  h1:not(.no-config),
  .title-h1 {
    font-size: 3.8rem;
    line-height: 4.8rem;
  }
}