.header {
  padding: 15px 0;

  &.white {
    .header {
      &-logo {
        &.black {
          display: none;
        }

        &.white {
          display: block;
        }
      }

      &-btn-mobile {
        span {
          background-color: #fff;
        }
      }
    }
  }

  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
  }

  &-logo {
    &.white {
      display: none;
    }

    &-mobile {
      display: none;
    }
  }

  &-nav {
    align-items: center;
    display: flex;

    &-links,
    &-networks {
      align-items: center;
      display: flex;
    }

    &-links {
      li {
        & + li {
          margin-left: 42px;
        }

        a {
          color: #323232;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 2.6rem; 
          letter-spacing: 0.5px;
          position: relative;
          text-transform: uppercase;

          @include hover() {
            &:hover {
              color: #902125;
            }
          }

          &::after {
            background-color: #902125;
            border-radius: 2px;
            bottom: -2px;
            content: '';
            height: 2px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            transition: 0.3s width;
            width: 0;
          }
        }
      }
    }

    &-networks {
      align-items: center;
      display: flex;
      margin-left: 65px;
      position: relative;

      &::before {
        background-color: #D9D9D9;
        content: '';
        display: block;
        height: 22px;
        left: -32px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
      }

      li {
        align-items: center;
        display: flex;
        justify-content: center;

        & + li {
          margin-left: 24px;
        }

        a {
          align-items: center;
          display: flex;
        }
      }
    }
  }

  &-btn-mobile {
    align-items: center;
    background-color: transparent;
    display: none;
    flex-direction: column;
    height: 36px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    z-index: 99;

    &.active {
      span {
        background-color: #fff;

        &:nth-child(1) {
          transform: rotate(-225deg);
        }
        
        &:nth-child(2) {
          transform: translateX(150%);
        }
        
        &:nth-child(3) {
          transform: rotate(45deg);
          margin-top: -13px;
        }
      }
    }

    span {
      background-color: #902125;
      border-radius: 2px;
      display: block;
      height: 2.9px;
      transition: 0.3s;
      width: 34px;

      & + span {
        margin-top: 8px;
      }

      svg {
        display: block;
      }
    }
  }

  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

@include responsive(992) {
  .header {
    &-nav {
      &-links {
        li {
          a {
            @include hover() {
              &:hover {
                &::after {
                  width: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include responsive(992) {
  .header {
    &.white {
      .header {
        &-nav {
          &-links {
            li {
              a {
                color: #fff;
                font-weight: 400;
    
                &::after {
                  background-color: #fff;
                }
              }
            }
          }
          &-networks {
            li {
              a {
                color: #fff;
                font-weight: 400;
    
                &::after {
                  background-color: #fff;
                }
    
                svg {
                  &.instagram {
                    path {
                      &.fill {
                        fill: #fff;
                      }
                     
                      &.stroke {
                        stroke: #902125;
                      }
                    }
                  }

                  &.twitter {
                    path {
                      fill: #fff;
                    }
                  }
                  
                  &.facebook {
                    path {
                      fill: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
   
  }
}

@include responsive(991) {
  .header {
    &-logo {
      &-mobile {
        display: block;
        padding-block: 15px;
        padding-left: 15px;
        margin-bottom: 20px;
      }
    }

    &-nav {
      background-color: #fff;
      display: block;
      height: 100vh;
      left: -120%;
      position: fixed;
      top: 0;
      transition: 0.3s left;
      width: 320px;
      z-index: 99;

    

      &.active {
        left: 0;
      }

      &-links {
        align-items: flex-start;
        flex-direction: column;
        max-height: calc(100vh - 78px - 50px - 110px);
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;

        &::-webkit-scrollbar {
          height: 3px;
          width: 3px;
        }
        
        &::-webkit--track {
          background-color: #eee;
        }
        
        &::-webkit-scrollbar-thumb {
          background-color: #cccc;
        }

        li {
          width: 100%;

          & + li {
            margin-left: 0;
            margin-top: 10px;
          }

          a {
            display: block;
            padding: 4px 15px;
            width: 100%;
          }
        }
      }

      &-networks {
        border-top: 1px solid #ccc;
        margin-left: 0;
        margin-top: 20px;
        padding-inline: 15px;
        padding-top: 24px;
      }
    }

    &-btn-mobile {
      display: flex;
    }
  }
}

@include responsive(599) {
  .header {
    &-nav {
      width: 250px;
    }
  }

  .hero {
    .container {
      &-content {
        display: none;
      }
    }
  }
}