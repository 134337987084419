.hero {
  position: relative;
  margin-bottom: 120px;

  &-slider {
    &-item {
      background-repeat: no-repeat;
      background-size: cover;
      height: 660px;
      padding: 105px 0 40px;

      &.active {
        .hero-slider-item-content {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }

      &-content {
        max-width: 550px;
        position: relative;
        opacity: 0;
        transform: translate3d(-100px, 0, 0);
        transition-duration: 0.9s;
        z-index: 99;
      }
    }

    &-status {
      align-items: center;
      background-color: rgba(144,33,37,.4);
      color: #fff;
      display: inline-flex;
      font-size: 1.4rem;
      font-weight: 400;
      height: 38px;
      line-height: 2.2rem;
      margin-bottom: 20px;
      padding: 6px 10px 6px 13px;
      position: relative;
      text-transform: uppercase;

      &::before {
        background-color: #902125;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 3px;        
      }

      small {
        font-size: 1.6rem;
        line-height: 2.6rem;
        margin-right: 10px;
      }
    }
    
    &-title {
      color: #fff;
      margin-bottom: 16px;
    }

    &-description {
      color: #fff;
    }

    &-infos {
      align-items: flex-end;
      display: flex;
      margin-bottom: 32px;
    }
    
    &-list {
      margin-top: 16px;
      margin-right: 20px;

      li {
        align-items: center;
        color: #fff;
        display: flex;
        font-size: 1.45rem;
        line-height: 1.8rem;

        span {
          align-items: center;
          display: flex;
          height: 32px;
          justify-content: center;
          margin-right: 9px;
          width: 32px;
        }
      }
    }
    
    &-more-info {
      color: #fff;
      font-size: 1.45rem;
      line-height: 1.8rem;
      opacity: 0.5;
      padding-bottom: 7px;
    }

    &-buttons {
      margin-bottom: 147px;
      margin-top: -147px;

      button {
        align-items: center;
        background-color: rgba(217, 217, 217, 0.20);
        border-radius: 2px;
        display: flex;
        height: 56px;
        justify-content: center;
        width: 56px;

        & + button {
          margin-top: 10px;
        }
      }
    }
  
    &-progress {
      .hero-slider-progress-line {
        &.loading {
          div {
            transition: 6.3s width;
            width: 100%;
          }
        }
      }

      &-line {
        background-color: rgba(217,217,217,0.2);
        border-radius: 2px;
        height: 3px;
        margin-bottom: 7px;
        overflow: hidden;
        position: relative;
        width: 168px;

        div {
          background-color: #fff;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 0;
        }
      }

      &-pagination {
        color: #fff;
        display: flex;
        font-size: 1.5rem;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 2.5rem;
        margin-left: auto;
        max-width: max-content;
      }
    }
  }

  &-decoration {
    background: url(../../src/img/hero-bottom-decoration.svg) no-repeat top center;
    bottom: -23.02px;
    height: 75.02px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 219px;
    z-index: 1;

    div {
      display: flex;
      height: 100%;
      justify-content: center;
      position: relative;
      width: 100%;

      span {
        align-items: center;
        background: linear-gradient(190deg, #292929 7.27%, #0C0C0C 95.17%);;
        border-radius: 50%;
        display: flex;
        height: 50px;
        justify-content: center;
        margin-top: 16.02px;
        opacity: 0;
        transform: translate3d(0,30px,0);
        transition: 0.6s;
        width: 50px;
        
        &.active {
          opacity: 1;
          transform: translate3d(0,0,0);
        }
      }
    }
  }

  .container-content {
    height: 100%;
    /* left: 50%; */
    position: relative;
    top: 0;
    width: 100%;
    z-index: 1;

    &-box {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      top: -238.5px;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 15px;
      touch-action: none;

      &.active {
        opacity: 1;
        pointer-events: unset;
        touch-action: unset;
      }
    }
  }
}

.our-enterprises {
  margin-bottom: 140px;
  overflow: hidden;

  &-top {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 52px;

    .right {
      padding-bottom: 4px;
  
      a {
        color: #323232;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.6rem;
        letter-spacing: 0.32px;
  
        svg {
          bottom: -1px;
          margin-left: 5px;
          position: relative;
        }
      }
    }
  }

  &-list {
    display: flex;

    .swiper-slide {
      opacity: 0.4;
      transition: 0.3s opacity;

      &.swiper-slide-prev,
      &.swiper-slide-active {
        opacity: 1 !important;
      }
    }
  }

  &-bottom {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    opacity: 0;
    pointer-events: none;
    touch-action: none;
    transition: 0.3s opacity;

    &.active {
      opacity: 1;
      pointer-events: initial;
      touch-action: initial;
    }

    &-controls {
      align-items: center;
      display: flex;
      justify-content: space-between;
      
      button {
        align-items: center;
        background-color: #EEE;
        border-radius: 2px;
        display: flex;
        height: 40px;
        justify-content: center;
        transition: 0.3s opacity;
        width: 40px;
        
        &.swiper-button-disabled {
          opacity: 0.3;
        }

        & + button {
          margin-left: 8px;
        }
      }
    }

    .swiper-pagination {
      position: initial;
    }
  }

  .swiper {
    overflow: unset;
    width: 100%;
  }
}

.about {
  overflow: hidden;

  &.hide-btn {
    .about {
      &-content {
        .btn {
          display: none;
        }
      }
    }
  }

  &-img {
    position: relative;

    &-banner {
      left: -32px;
      margin-left: calc(-224px + -32px);
      max-width: unset;
      position: relative;
    }

    &-stamp {
      bottom: 0;
      opacity: 0.8;
      position: absolute;
      right: 58px;

      &-star {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        opacity: 0.9;
      }

      &-text {
        animation: aboutStampRotate 14s infinite linear;
      }

      div {
        position: relative;
      }
    }
  }
  
  &-content {
    max-width: 482px;
    width: 100%;

    &-list {
      margin-bottom: 32px;

      li {
        align-items: center;
        color: #6F6F6F;
        display: flex;
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0.32px;
        line-height: 2.4rem;

        & + li {
          margin-top: 20px;
        }

        span {
          align-items: center;
          background-color: #55AA67;
          border: 4px solid #D2ECD8;
          border-radius: 50%;
          display: flex;
          height: 36px;
          justify-content: center;
          margin-right: 10px;
          min-height: 36px;
          min-width: 36px;
          width: 36px;
        }
      }
    }

    .box-title {
      margin-bottom: 24px;
    }

    p {
      color: #6F6F6F;
    }
  }

  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
}

.numbers {
  margin-block: 160px;

  &-list {
    display: flex;
    justify-content: space-between;
  }

  &-item {
    strong {
      color: #323232;
      display: block;
      font-family: $secondary-font;
      font-size: 5.4rem;
      font-weight: 400;
      line-height: 6.2rem;
      margin-bottom: 10px;

      span {
        font-family: inherit;
        font-size: 2.7rem;
        line-height: 3.2rem;
      }
    }

    p {
      color: #6F6F6F;
      font-size: 1.5rem;
      line-height: 2.2rem;
    }
  }
}

.video {
  height: 644px;
  overflow: hidden;
  padding: 298px 0;
  position: relative;
  margin-bottom: 259px;

  &.playing {
    .video-content {
      .btn-video {
        &.play {
          opacity: 0;
          pointer-events: none;
          touch-action: none;
        }

        &.pause {
          opacity: 0.5;
          pointer-events: initial;
          touch-action: initial;

          &:hover {
            opacity: 1;
          }
        }
      }

      h2 {
        opacity: 0;
      }
    }
  }

  &-content {
    .btn-video {
      left: 50%;
      position: absolute;
      transform: translate3d(-50%, 0, 0);
      
      &.play {
        top: 280px;
      }
    }

    h2 {
      color: #fff;
    }
  }
  
  video {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}

@include responsive(1280) {
  .our-enterprises {
    &-list {
      .swiper-slide {
        opacity: 0.4;
  
        &:nth-child(-n+3),
        &.swiper-slide-active,
        &.swiper-slide-prev,
        &.swiper-slide-next,
        &.swiper-slide-next + .swiper-slide {
          opacity: 1;
        }
      }
    }
  }
}

@include responsive(1279) {
  .about {
    &-img {
      &-banner {
        margin-left: -320px;
        max-width: 840px;
      }

      &-stamp {
        right: 34px;
      }
    }
  }

  .numbers {
    &-item {
      max-width: 200px;
      width: 100%;

      & + .numbers-item {
        margin-left: 10px;
      }
    }

    strong {
      line-height: 3.6rem;

      span {
        display: block;
        margin-top: 4px;
      }
    }
  }

  .video {
    height: 550px;
    padding: 160px 0 0;

    &-content {
      h2 {
        text-align: center;
      }
    }

    video {
      height: 100%;
      object-fit: cover;
    }
  }
}

@include responsive(991) {
  .hero {
    &-slider {
      &-buttons {
        button {
          height: 46px;
          padding: 18px;
          width: 46px;
        }
      }
    }
  }

  .about {
    &-img {
      &-banner {
        max-width: 610px;
      }

      &-stamp {
        max-width: 100px;
        right: 24px;
      }
    }
  }

  .numbers {
    &-list {
      display: grid;
      gap: 60px 40px;
      grid-template-columns: 1fr 1fr;
      margin-inline: auto;
      max-width: 580px;
    }

    &-item {
      align-items: center;
      display: flex;
      flex-direction: column;

      & + .numbers-item {
        margin-left: 0;
      }

      strong,
      p {
        text-align: center;
      }
    }
  }
}

@include responsive('768-1279') {
  .our-enterprises {
    &-list {
      .swiper-slide {
        &:nth-child(-n+3):not(.swiper-slide-active),
        &.swiper-slide-next + .swiper-slide {
          opacity: 0.4;
        }

        &:nth-child(-n+2),
        &.swiper-slide-prev,
        &.swiper-slide-active + .swiper-slide {
          opacity: 1 !important;
        }
      }
    }
  }
}

@include responsive(767) {
  .hero {
    &-slider {
      &-title,
      &-description {
        text-align: center;
      }

      &-item {
        &-content {
          align-items: center;
          display: flex;
          flex-direction: column;
        }

        .btn {
          margin-top: 32px;
        }
      }

      &-infos {
        margin-right: 0;
      }

      &-list {
        display: flex;

        li {
          & + li {
            margin-left: 32px;
          }
        }
      }

      &-more-info {
        display: none;
      }
    }
  }

  .about {
    &-img {
      margin-bottom: 50px;

      &-banner {
        margin-left: -40px;
        max-width: 100%;
      }
    }

    &-content {
      align-items: center;
      display: flex;
      flex-direction: column;

      .box-title {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title,
        p {
          text-align: center;
        }
      }
    }

    .container {
      flex-direction: column;
    } 
  }

  .video {
    height: unset;
    padding: 100px 0 140px;

    &-content {
      align-items: center;
      display: flex;
      flex-direction: column;

      .btn-video {
        &.play {
          position: initial;
          transform: unset;
        }

        &.pause {
          top: 204px;
        }
      }

      h2 {
        margin-bottom: 20px;
      }
    }
  }
}

@include responsive(600) {
  // .our-enterprises {
  //   &-list {
  //     .swiper-slide {
  //       &:nth-child(-n+2):not(.swiper-slide-active),
  //       &.swiper-slide-next + .swiper-slide {
  //         opacity: 0.4;
  //       }

  //       &:nth-child(-n+1),
  //       &.swiper-slide-prev,
  //       &.swiper-slide-active {
  //         opacity: 1 !important;
  //       }
  //     }
  //   }
  // }
}

@include responsive(599) {
  .hero {
    &-slider {
      &-item {
        height: 580px;
        overflow: hidden;
        padding-top: 95px;
      }

      &-infos {
        align-items: center;
        display: none;
        flex-direction: column;
        margin-top: 9px;
      }

      &-list {
        display: flex;
        margin: 20px 0 0;

        li {
          & + li {
            margin-left: 20px;
          }

          span {
            margin-right: 4px;
          }
        }
      }
    }

    &-decoration {
      background-size: contain;
      bottom: -5.8%;
      width: 180px;

      div {
        span {
          height: 44px;
          margin-top: 13px;
          width: 44px;
        }
      }
    }
  }

  .about {
    &-img {
      &-stamp {
        right: 10px;
      }
    }
  }

  .numbers {
    &-list {
      grid-template-columns: 1fr;
    }

    &-item {
      flex-direction: column;
      margin-inline: auto;
      max-width: 300px;
      
      & + .numbers-item {
        margin-inline: auto;
      }
    }
  }

  .video {
    &-content {
      .btn-video {
        &.pause {
          top: 196px;
        }
      }
    }
  }

  .our-enterprises {
    &-top {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 32px;

      .right {
        margin-top: 8px;
      }
    }
  }
}