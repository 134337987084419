.enterprises-item {
  width: 100%;

  &-img {
    max-height: 455px;
    overflow: hidden;
    position: relative;
    user-select: none;
    width: 100%;

    img {
      width: 100%;
    }

    span {
      align-items: center;
      background-color: #fff;
      border-radius: 2px;
      bottom: 14px;
      color: #902125;
      display: inline-flex;
      height: 29px;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 700;
      left: 17px;
      line-height: 1.6rem;
      padding: 8px 7px 6px;
      position: absolute;
    }
  }

  &-box {
    border: 1px solid #E4E4E4;
    border-radius: 0px 0px 4px 4px;
    padding: 22px 17px 24px;
    user-select: none;
  }

  &-infos {
    strong {
      display: block;
      font-family: $secondary-font;
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 2.2rem;
      margin-bottom: 6px;
    }

    p {
      border-bottom: 1px solid #D9D9D9;
      color: #323232;
      min-height: 58.9px;
      font-size: 1.5rem;
      line-height: 2.1rem;
      margin-bottom: 16px;
      opacity: 0.95;
      padding-bottom: 16px;
    }
  }

  &-benefits {
    align-items: center;
    display: flex;

    li {
      align-items: center;
      display: flex;
      min-width: 40%;

      & + li {
        margin-left: 24px;
      }

      div {
        align-items: center;
        display: flex;
        height: 24px;
        justify-content: center;
        margin-right: 4px;
        min-height: 24px;
        min-width: 24px;
        width: 24px;
      }

      span {
        color: #323232;
        display: -webkit-box;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8rem;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &-link {
    align-items: center;
    color: #902125;
    display: flex;
    font-family: $secondary-font;
    font-size: 1.5rem;
    font-weight: 600;
    height: 23px;
    line-height: normal;
    margin-top: 26px;
    max-width: max-content;
    text-transform: uppercase;

    @include hover() {
      &:hover {
        svg {
          margin-left: 15px;
        }
      }
    }

    svg {
      margin-left: 12px;
      position: relative;
      top: -0.5px;
      transition: 0.3s margin-left;
    }
  }
}

@include responsive(600) {
  .enterprises-item {
    &-infos {
      strong {
        min-height: 43.98px;
      }
    }

    &-benefits {
      li {
        max-width: 50%;
      }
    }
  }
}

@include responsive(479) {
  .enterprises-item {
    &-benefits {
      align-items: flex-start;
      flex-direction: column;
      
      li {
        & + li {
          margin-left: 0;
          margin-top: 6px;
        }
      }
    }
  }
}