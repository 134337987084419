.enterprises-single {
  padding-bottom: 140px;

  &-hero {
    height: auto;
    margin-bottom: 70px;
    padding-bottom: 100px;

    .box-title {
      max-width: 840px;
    }
  }

  &-content {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    position: relative;

    &-left {
      height: 100%;
      max-width: 204px;
      padding-top: 20px;
      position: relative;
      position: sticky;
      top: 0;
      width: 100%;

      h2 {
        color: #1F1F1F;
        font-size: 2.2rem;
        line-height: 2.8rem;
        margin-bottom: 28px;
      }

      nav {
        ul {
          li {
            & + li {
              margin-top: 14px;
            }

            a {
              color: #323232;
              font-size: 1.4rem;
              font-weight: 500;
              line-height: 2rem;
              padding-left: 26px;
              position: relative;
              letter-spacing: 0.5px;
              line-height: 20px;
              transition: 0.3s;
              opacity: 0.7;
              text-transform: uppercase;

              &::before {
                background-color: #515151;
                content: '';
                display: block;
                height: 2px;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transition: 0.3s;
                width: 0;
              }

              @include hover() {
                &:hover {
                  &::before {
                    width: 16px;
                  }
                }
              }

              &.active {
                color: #902125;
                max-width: 400px;
                opacity: 1;

                &::before {
                  background-color: #902125;
                  width: 16px;
                }
              }
            }
          }
        }
      }
    }

    &-right {
      max-width: 914px;
      padding-top: 320px;
      position: relative;
      width: 100%;

      &::before {
        background-color: #D9D9D9;
        content: '';
        display: block;
        height: 100%;
        left: -33px;
        position: absolute;
        top: 0;
        width: 1px;
      }

      .box-title {
        p {
          color: #515151;
          margin-top: 4px;

          & + p {
            margin-top: 6px;
          }
        }
      }

      h2 {
        font-size: 3.2rem;
        line-height: 3.6rem;
      }
    }

    .box-title {
      margin-bottom: 42px;
    }
  }

  &-banner {
    height: 423px;
    margin-top: calc(-136px);
    position: absolute;
    right: -104px;
    top: 0;
    width: 912px;
    z-index: 1;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &-info {
    display: flex;

    &-img {
      height: 599px;
      margin-right: 28px;
      width: 472px;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    &-content {
      max-width: 414px;
      padding-top: 10px;
      width: 100%;

      .info {
        strong {
          color: #902125;
        }
      }
   
      ul {
        display: grid;
        gap: 18px 16px;
        grid-template-columns: 1fr 1fr;
        margin-top: 24px;

        li {
          align-items: center;
          display: flex;
          min-width: 190px;
          width: 100%;

          div {
            align-items: center;
            display: flex;
            height: 24px;
            justify-content: center;
            margin-right: 8px;
            width: 24px;
          }

          span {
            color: #515151;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.8rem;
          }
        }
      }
    }
  }

  &-gallery {
    &-grid {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(3, 1fr);
      max-width: max-content;
    }

    &-item {
      height: 290px;
      position: relative;
      width: 294px;

      &:hover {
        .enterprises-single-gallery-item-overlay {
          opacity: 1;

          div {
            opacity: 1;
            transform: translate3d(0,0,0);
          }
        }
      }

      &-overlay {
        align-items: center;
        background-color: rgba(0,0,0,.7);
        display: flex;
        height: 100%;
        justify-content: center;
        opacity: 0;
        transition: 0.3s opacity;
        width: 100%;

        div {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transform: translate3d(0,30px,0);
          opacity: 0;
          transition: 0.3s;
        }

        span {
          display: block;
          color: #FFF;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 1.8rem;
          margin-top: 5px;
          text-align: center;
          text-transform: uppercase;
        }
      }

      a {
        display: flex;
        height: 100%;
        width: 100%;

        > img {
          left: 0;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          width: 100%;
        }

        div {
          z-index: 1;
        }

        button {
          display: none;
        }
      }
    }

    .btn {
      display: block;
      margin: 32px auto 0;
      max-width: max-content;
    }
  }

  &-location {
    .box-title {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;

      .right {
        p {
          align-items: center;
          display: flex;
          font-size: 1.4rem;
          line-height: 2.2rem;
  
          svg {
            margin-left: 10px;
          }
        }
      }
    }
  }

  &-plan {
    &-slider {
      align-items: center;
      display: flex;

      &-content {
        width: calc(100% - 40px);

        div {
          background-size: contain !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          height: 450px; 
  
          img {
            // height: 450px;
            // object-fit: cover;
            // width: 100%;
            visibility: hidden;
          }
        }
      }
      
      button {
        align-items: center;
        background-color: #f3f3f3;
        border-radius: 2px;
        display: flex;
        height: 34px;
        justify-content: center;
        transition: 0.3s opacity;
        width: 27px;

        &.swiper-button-disabled {
          opacity: 0.3;
          pointer-events: none;
          touch-action: none;
        }
      }

    }
  }

  &-video {
    &.playing {
      .btn-video {
        &.play {
          opacity: 0;
          pointer-events: none;
          touch-action: none;
        }

        &.pause {
          opacity: 1;
          pointer-events: initial;
          touch-action: initial;
        }
      }

      .enterprises-single-video-content {
        h2 {
          opacity: 0;
        }
      }
    }

    &-content {
      position: relative;
      height: 409px;
      overflow: hidden;

      .btn-video {
        left: 50%;
        position: absolute;
        transform: translate3d(-50%, -50%, 0);

        &.play {
          top: calc(50% - 20px);
        }

        &.pause {
          bottom: 10px;
        }
      }

      video {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: -1;
      }
    }
  }

  .mt-enterprises-single {
    margin-top: 100px;
  }
}

@include responsive(1439) {
  .enterprises-single {
    position: relative;

    &-content {
      position: unset;

      &-wrapper {
        overflow: hidden;

        left: 0;
        position: relative;
        width: 100%;

     
      }

      &-right {
        position: unset;
        
        width: 100%;
      }
    }

    &-banner {
      right: 0;
    }
  }
}

@include responsive(1279) {
  .enterprises-single {
    &-banner {
      height: 320px;
      top: -28px;
      width: 610px;
    }

    &-content {
      &-right {
        max-width: 680px;
        padding-top: 177px;
      }
    }

    &-info {
      &-img {
        height: 410px;
      }
    }

    &-gallery {
      &-grid {
        grid-template-columns: repeat(2, 1fr);
        max-width: 100%;

        li {
          width: 100%;
        }
      }
    }

    &-plan {
      &-slider {
        &-content {
          div {
            img {
              height: 380px;
            }
          }
        }
      }
    }

    &-video {
      &-content {
        height: auto;

        .btn-video {
          &.play {
            top: calc(50% - 8px);
          }
        }

        video {
          position: unset;
        }
      }
    }
  }
}

@include responsive(1120) {
  .enterprises-single {
    &-banner {
      height: 320px;
      width: 600px;
    }
  }
}

@include responsive(991) {
  .enterprises-single {
    &-banner {
      top: 80px;
      width: 100%;
    }

    &-content {
      flex-direction: column;
      padding-top: 190px;
      padding-top: 264px;
      position: relative;

      &-left {
        background-color: #fff;
        max-width: 100%;
        opacity: 1 !important;
        overflow: hidden;
        padding-top: 0;
        top: 0;
        transform: translateZ(0) !important;
        z-index: 9;

        h2 {
          display: none;
        }

        nav {
          ul {
            display: flex;
            overflow-y: hidden;
            overflow-x: auto;

            &::-webkit-scrollbar {
              height: 3px;
              width: 3px;
            }
            
            &::-webkit--track {
              background-color: #eee;
            }
            
            &::-webkit-scrollbar-thumb {
              background-color: #cccc;
            }

            li {
              transition: 0.3s;
              max-width: 400px;

              & + li {
                margin-top: 0;
              }

              &:not(:last-child) {
                margin-right: 22px;
              }

              &.hide {
                opacity: 0;
                max-width: 0;
                margin-inline: 0;

                a {
                  padding-inline: 0;
                }
              }

              a {
                padding-block: 14px 10px;
              }
            }
          }
        }
      }

      &-wrapper {
        [data-aos="fade-left"] {
          transform: translateZ(0) !important;
        }
      }

      &-right {
        margin-top: 0;
        max-width: 100%;
        padding-top: 0;

        &::before {
          display: none;
        }
      }
    }

    &-about {
      padding-top: 80px;
    }

    &-location {
      .box-title {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    .mt-enterprises-single {
      margin-top: 0;
      padding-top: 80px;
    }
  }
}

@include responsive(767) {
  .enterprises-single {
    &-info {
      align-items: flex-start;
      flex-direction: column;

      &-img {
        height: 400px;
        margin-bottom: 24px;
        margin-right: 0;

        img {
          object-fit: unset;
          width: unset;
        }
      }
    }

    &-gallery {
      &-grid {
        li {
          height: 260px;
        }
      }
    }

    &-plan {
      &-slider {
        &-content {
          div {
            img {
              height: 320px;
            }
          }
        }
      }
    }

    &-video {
      &-content {
        .btn-video {
          &.pause {
            height: 40px;
            width: 40px;

            .btn-video {
              &-icon {
                left: calc(50% - 0.5px);
                width: 12.5px;
              }
            }

            span {
              bottom: -20px;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}

@include responsive(599) {
  .enterprises-single {
    &-hero {
      padding-bottom: 70px;
    }

    &-content {
      padding-top: 224px;
    }

    &-banner {
      height: 280px;
    }

    &-info {
      &-content {
        ul {
          gap: 18px 4px;
          grid-template-columns: 1fr;
        }
      }
    }

    &-gallery {
      &-grid {
        gap: 10px;

        li {
          height: 160px;
        }
      }
    }

    &-plan {
      &-slider {
        &-content {
          div {
            img {
              height: 220px;
            }
          }
        }
      }
    }
  }
}