.modal-gallery {
  align-items: center;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  touch-action: none;
  top: 0;
  transition: 0.3s;
  visibility: hidden;
  width: 100%;
  z-index: 999;

  &.active {
    display: flex;
    opacity: 1;
    pointer-events: initial;
    touch-action: initial;
    visibility: initial;

    .modal-gallery-content {
      opacity: 1;
      transform: translate3d(0,0,0);
    }
  }

  &-content {
    background-color: #fff;
    min-width: 300px;
    margin: 20px;
    max-width: 660px;
    opacity: 0;
    padding: 20px;
    position: relative;
    transform: translate3d(0,-50px,0);
    transition: 0.3s;
    width: 100%;
  }

  &-close {
    align-items: center;
    background-color: #fff;
    border: solid 2px #902125;
    border-radius: 2px;
    display: flex;
    height: 34px;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 34px;
    z-index: 1;
  }

  &-box {
    margin-bottom: 6px;
    position: relative;

    &-img {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 400px;
      max-width: 100%;
      transition: 0.3s;
      width: 660px;

      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }

    .btn-prev,
    .btn-next {
      background-color: #902125;
      height: 34px;
      width: 27px;
      border-radius: 2px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .btn-prev {
      left: -12px;
    }
    
    .btn-next {
      right: -12px;
    }
  }

  &-nav {
    ul {
      display: flex;
      margin-inline: auto;
      max-width: max-content;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        height: 8px;
        width: 3px;
      }
      
      &::-webkit--track {
        background-color: #a7a7a7;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: #a7a7a7;
      }

      li {
        & + li {
          margin-left: 6px;
        }

        button {
          border-color: transparent;
          border-style: solid;
          border-width: 3px;
          height: 50px;
          min-width: 58px;
          max-width: 58px;
          transition: 0.3s;
          width: 100%;
  
          &.active {
            border-color: #902125;
            touch-action: none;
            pointer-events: none;
          }
  
          img {
            background-size: cover;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

@include responsive(767) {
  .modal-gallery {
    &-content {
      max-width: 480px;
      padding: 10px;
    }

    &-box {
      &-img {
        height: 300px;
      }
    }

    &-nav {
      ul {
        li {
          & + li {
            margin-left: 4px;
          }
          
          button {
            height: 40px;
            min-width: 40px;
            max-width: 40px;
          }
        }
      }
    }
  }
}

@include responsive(599) {
  .modal-gallery {
    &-nav {
      ul {
        &::-webkit-scrollbar {
          height: 3px;
        }
      }
    }
  }
}