.contact {
  margin-bottom: 140px;

  &-wrapper {
    display: flex;
    position: relative;
  }

  &-box {
    left: -20px;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    max-width: max-content;

    &-bg {
      opacity: 0.12;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: -61px;
      touch-action: none;
      z-index: 1;
    }

    &-img {
      bottom: 0;
      left: -17px;
      pointer-events: none;
      position: absolute;
      touch-action: none;
      z-index: 2;

      .message-01,
      .message-02 {
        min-width: max-content;
        position: absolute;
        
        p {
          align-items: center;
          border-radius: 2px;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
          color: #323232;
          height: 29px;
          display: inline-flex;
          font-size: 1.3rem;
          font-weight: 500;
          letter-spacing: .25px;
          line-height: 1.8rem;
          padding-inline: 10px 17px;
        }

        span {
          margin-left: 4px;
        }
        
        img,
        svg {
          bottom: 4.06px;
          position: absolute;
          right: 3.71px;
        }
      }

      .message-01 {
        top: 195px;
        left: 65.5px;

        p {
          background-color: #fff;
        }
      }

      .message-02 {
        top: 234px;
        right: 50px;

        p {
          background-color: #DEFFD8;
        }
      }

      div {
        position: relative;
      }

      img {
        &.mobile {
          display: none;
        }
      }
    }

    &-content {
      background: linear-gradient(180deg, #902125 0%, #640C0F 100%, #640C0F 100.01%);
      padding: 57.5px 49px 76.5px 322px;
      position: relative;

      .contact-decoration-01,
      .contact-decoration-02 {
       position: absolute;
      }

      .contact-decoration-01 {
        bottom: -19px;
        left: -34px;
      }

      .contact-decoration-02 {
        right: -29px;
        top: -21px;
      }
    }

    &-actions {
      .btn {
        & + .btn {
          margin-left: 32px;
        }
      }
    }

    .box-title {
      .sub-title,
      .title {
        color: #fff;
      }

      .sub-title {
        font-weight: 400;
      }

      .title {
        margin-bottom: 32px;
      }
    }
  }
}

@include responsive(1279) {
  .contact {
    &-box {
      left: 0;

      &-img {
        left: -40px;

        .message-01 {
          left: 70px;
          top: 170px;
        }

        .message-02 {
          right: 20px;
          top: 214px;
        }
      }

      &-content {
        padding: 57.5px 26px 76.5px 386px;
      }

      &-actions {
        .btn {
          & + .btn {
            margin-left: 12px;
          }
        }
      }
    }

    .container {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
}

@include responsive(991) {
  .contact {
    &-box {
      &-bg {
        bottom: 0;
        top: unset;
      }

      &-img {
        left: -40px;
        max-width: 330px;
      }

      &-content {
        padding: 54px 26px 60px 308px;
      }

      &-actions {
        .btn {
          & + .btn {
            margin-left: 0;
            margin-top: 12px;
          }
        }
      }

      .box-title {
        .title {
          font-size: 3.8rem;
          line-height: 4.6rem;

          br {
            display: none;
          }
        }
      }
    }
  }
}

@include responsive(767) {
  .contact {
    &-wrapper {
      flex-direction: column;
    }

    &-box {
      &-content {
        background: linear-gradient(180deg, #902125 0%, #640C0F 120%, #640C0F 100.01%);
      }

      &-img {
        left: calc(50% - 32px);
        top: -150px;
        transform: translateX(-50%);

        .message-02 {
          right: -20px;
        }

        img {
          &.desktop {
            display: none;
          }

          &.mobile {
            display: block;
          }
        }
      }

      &-content {
        padding: 260px 26px 60px;
      }

      &-actions {
        display: flex;
        flex-direction: column;

        .btn {
          max-width: 100%;
          text-align: center;
          width: 100%;
        }
      }

      .box-title {
        align-items: center;
        display: flex;
        flex-direction: column;

        .sub-title,
        .title {
          text-align: center;
        }
      }
    }

    &-actions {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
}

@include responsive(599) {
  .contact {
    &-box {
      &-img {
        left: calc(50% - 20px);
        width: 250px;

        .message-01 {
          left: 60px;
        }

        .message-02 {
          right: -60px;
        }
      }

      &-content {
        padding: 180px 26px 60px;
      }

      .box-title {
        .title {
          font-size: 3rem;
          line-height: 3.8rem;
        }
      }
    }
  }
}

@include responsive(374) {
  .contact {
    &-box {
      &-img {
        .message-01 {
          left: 60px;
        }
      }

      &-content {
        padding: 180px 16px 60px;
      }

      &-actions {
        .btn {
          padding: 13px 6px 14px;
        }
      }
    }
  }
}