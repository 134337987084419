.contact {
  &-hero {
    height: auto;
    padding-bottom: 104.5px;

    &-phone,
    .box-title {
      position: relative;
      z-index: 1;
    }

    &-phone {
      margin-top: 40px;
      max-width: 277px;

      >p {
        align-content: center;
        display: flex;
        color: #fff;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 2.2rem;
        margin-bottom: 8px;
        text-transform: uppercase;

        img {
          margin-right: 10px;
        }
      }

      div {
        align-items: center;
        display: flex;

        p {
          color: #878787;
          font-size: 1.6rem;
          line-height: 2.2rem;
        }

        span {
          display: inline-block;
          color: #878787;
          font-size: 1.3rem;
          font-weight: 500;
          letter-spacing: 0.5px;
          line-height: 1.6rem;
          margin-inline: 8px;
          opacity: 0.5;
          text-transform: uppercase;
        }
      }
    }

    .box-title {
      p {
        color: #878787;
        font-weight: 400;
        opacity: 1;
      }
    }
  }

  &-form {
    &-box {
      background-color: #FFF;
      border-radius: 2px;
      margin-inline: auto 0;
      margin-top: -364px;
      padding: 34px 30px 41px 30px;
      position: relative;
      width: 429px;
      z-index: 1;

      &-top {
        h2 {
          color: #1F1F1F;
          font-size: 2.2rem;
          font-weight: 400;
          line-height: 2.8rem;
          margin-bottom: 32px;
          text-align: center;
        }
      }

      &-main {
        .wpcf7-spinner {
          left: 50%;
          margin: 0;
          position: absolute;
          top: calc(50% + 12px);
          transform: translate3d(-50%, -50%, 0);
        }

        p {
          position: relative;
        }
      }
    }

    &-img {
      bottom: -70px;
      position: absolute;
      right: -112px;
    }

    .container {
      position: relative;
    }

    .btn {
      margin-top: 22px;
    }

    .wpcf7-submit {
      border-radius: 2px !important;
      background-color: #902125;
      border-color: #902125 !important;
      color: #fff !important;
      cursor: pointer;
      font-size: 1.6rem !important;
      font-weight: 500 !important;
      height: 50px !important;
      line-height: 2.3rem !important;
      margin-top: 22px;
      padding: 14px 36px 12px !important;
      position: relative;
      text-transform: uppercase;
      transition: .3s !important;
      width: 100%;
    }

    .wpcf7 {
      form {
        &.invalid,
        &.unaccepted,
        &.payment-required {
          .wpcf7-response-output {
            margin-bottom: 0;
            margin-top: 12px;
          }
        }
      }
    }

    .wpcf7-not-valid-tip {
      font-size: .7em;
    }
  }

  &-location {
    margin-bottom: 140px;
    margin-top: 190px;

    &-left {
      margin-inline: -112px 30px;
      max-width: 704px;
      position: relative;
      width: 100%;
    }

    &-right {
      max-width: 376px;

      .box-title {
        p {
          align-items: center;
          color: #6F6F6F;
          display: flex;

          img {
            margin-right: 10px;
          }
        }
      }
    }

    .container {
      align-items: center;
      display: flex;
    }
  }
}

@include responsive(1279) {
  .contact {
    &-form {
      &-img {
        bottom: -90px;
      }
    }
  }
}

@include responsive(992) {
  .page-template-contact {
    .header {
      &.white {
        .header {
          &-nav {
            &-networks {
              li {
                a {
                  svg {
                    path {
                      &.stroke {
                        stroke: #131313;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include responsive(991) {
  .contact {
    &-form {
      &-box {
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 12%), 0 8px 10px -16px rgba(0, 0, 0, 12%);
        margin-inline: auto;
        margin-top: -60px;
        max-width: 100%;
      }

      &-img {
        bottom: unset;
        top: -20px;
        right: -60%;
      }
    }

    &-location {
      margin-top: 140px;

      &-left {
        margin-inline: auto;
        margin-top: 42px;
      }

      &-right {
        align-items: center;
        display: flex;
        flex-direction: column;

        .box-title {
          align-items: center;
          display: flex;
          flex-direction: column;

          .title {
            text-align: center;
          }

          p {
            flex-direction: column;
          }
        }
      }

      .container {
        flex-direction: column-reverse;
        margin-inline: auto;
        margin-top: 42px;
        max-width: 100%;
      }
    }
  }
}