@mixin hover() {
  @media (hover: hover) {
    @content;
  }
}

@mixin responsive($deviceWidth) {
  // ==== MIN. AND MAX Breakpoint
  @if ($deviceWidth == '768-1279') { 
    @media screen and (min-width: 768px) and (max-width: 1279px) {
      @content;
    }
  }

  // ==== MAX. Breakpoints
  @if ($deviceWidth == 1439) {
    @media (max-width: 1439px) {
      @content;
    }
  }

  @if ($deviceWidth == 1279) {
    @media (max-width: 1279px) {
      @content;
    }
  }

  @if ($deviceWidth == 1120) {
    @media (max-width: 1120px) {
      @content;
    }
  }

  @if ($deviceWidth == 991) {
    @media (max-width: 991px) {
      @content;
    }
  }

  @if ($deviceWidth == 767) {
    @media (max-width: 767px) {
      @content;
    }
  }

  @if ($deviceWidth == 599) {
    @media (max-width: 599px) {
      @content;
    }
  }

  @if ($deviceWidth == 479) {
    @media (max-width: 479px) {
      @content;
    }
  }

  @if ($deviceWidth == 374) {
    @media (max-width: 374px) {
      @content;
    }
  }

  // ==== MIN. Breakpoint
  @if ($deviceWidth == 1441) { 
    @media (min-width: 1441px) {
      @content;
    }
  }

  @if ($deviceWidth == 1280) { 
    @media (min-width: 1280px) {
      @content;
    }
  }

  @if ($deviceWidth == 992) { 
    @media (min-width: 992px) {
      @content;
    }
  }

  @if ($deviceWidth == 768) { 
    @media (min-width: 768px) {
      @content;
    }
  }

  @if ($deviceWidth == 600) { 
    @media (min-width: 600px) {
      @content;
    }
  }

  @if ($deviceWidth == 480) { 
    @media (min-width: 480px) {
      @content;
    }
  }
}