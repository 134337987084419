.swiper-pagination {
  align-items: center;
  display: flex;

  &-bullets {
    max-width: max-content;
  }

  &-bullet {
    background-color: #BABABA;
    border-radius: 50%;
    height: 7px;
    margin: 0;
    opacity: 1;
    transition: 0.3s;
    width: 7px;

    & + .swiper-pagination-bullet {
      margin-left: 4px;
    }

    &-active {
      background-color: #902125;
      border-radius: 4px;
      width: 16px;
    }
  }

  &-button {
    align-items: center;
    background-color: #EFEFEF;
    border-radius: 2px;
    color: #5C5C5C;
    display: flex;
    font-size: 1.4rem;
    font-weight: 500;
    font-weight: 600;
    height: 40px;
    justify-content: center;
    line-height: 2rem;
    transition: 0.3s background-color;
    width: 40px;

    &:not(.active) {
      @include hover() {
        &:hover {
          background-color: #e3e3e3;
        }
      }
    }

    &.active {
      background-color: #902125;
      color: #fff;
    }

    & + .swiper-pagination-button {
      margin-left: 10px;
    }
  }
}