.container {
  margin: 0 auto;
  max-width: calc(121.6rem + 30px);
  padding: 0 15px;
  width: 100%;
}

@include responsive(1279) {
  .container {
    max-width: calc(94rem + 30px);
  }
}

@include responsive(991) {
  .container {
    max-width: calc(71.6rem + 30px);
  }
}

@include responsive(767) {
  .container {
    max-width: calc(55rem + 30px);
  }
}

@include responsive(599) {
  .container {
    max-width: calc(44.4rem + 30px);
  }
}

@include responsive(479) {
  .container {
    max-width: 37.5rem;
    padding: 0 12px;
  }
}

@include responsive(439) {
  .container {
    max-width: calc(34rem + 30px);
  }
}

@include responsive(374) {
  .container {
    max-width: 32rem;
  }
}