@import './config/variables';

*,
*::after,
*::before {
  border: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $primary-font;
}

.show-large {
  display: none;
}

.w-100 {
  width: 100%;
}

.overlay-bg {
  background-color: rgba(0,0,0,0.8);
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  visibility: hidden;
  top: 0;
  touch-action: none;
  transition: 0.3s ease-in-out;
  width: 100%;
  z-index: 99;
  
  &.active {
    opacity: 1;
    visibility: initial;
    pointer-events: initial;
    touch-action: initial;
  }
}

.html_css,
.html_css body {
  overflow: unset;
}

html,
body {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
}

body {
  color: #323232;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.7rem;
}

ul {
  list-style: none;
}

button {
  border: none;
  cursor: pointer;
  display: inline-block;
  font-weight: inherit;
}

a {
  display: inline-block;
  text-decoration: none;
  transition: 0.3s color;
}

img,
video {
  display: block;
  max-width: 100%;
}

@include responsive(1441) {
  .hide-large {
    display: none;
  }

  .show-large {
    display: block;
  }
}

@include responsive(991) {
  html.no-scroll-mobile,
  html.no-scroll-mobile body {
    overflow: hidden;
  }

  html,
  body {
    overflow-x: hidden;
  }
}