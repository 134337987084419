.footer {
  background-color: #121212;
  padding-block: 70px 30px;
  position: relative;
  overflow: hidden;

  &-bg {
    bottom: -0.5px;
    left: -15px;
    opacity: 0.05;
    pointer-events: none;
    position: absolute;
    touch-action: none;
  }

  &-main {
    align-items: flex-start;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    display: flex;
    justify-content: space-between;
    padding-bottom: 47px;

    &-left {
      max-width: 270px;

      p {
        color: #878787;
        margin-top: 16px;
      }
    }

    &-right {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      max-width: 717px;
      position: relative;
      width: 100%;

      &::before {
        background-color: #fff;
        content: '';
        display: block;
        height: 100%;
        left: -95.5px;
        opacity: 0.2;
        position: absolute;
        width: 1px;
      }
    }
  }

  &-bottom {
    align-content: center;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    &-left {
      p {
        color: #6F6F6F;
        opacity: 0.8;
      }
    }
    
    &-right {
      a {
        align-items: center;
        display: flex;

        @include hover() {
          &:hover {
            span {
              color: #fff;
              opacity: 1;
            }
          }
        }

        span {
          color: #6F6F6F;
          opacity: 0.8;
          transition: 0.3s;
        }

        img {
          bottom: -1px;
          margin-left: 6px;
          position: relative;
        }
      }
    }
  }

  &-box {
    &-pages {
      ul {
        li {
          & + li {
            margin-top: 11px;
          }

          a {
            color: #FFF;
            font-size: 1.5rem;
            font-weight: 300;
            letter-spacing: 0.5px;
            line-height: 2.5rem;
            opacity: 0.6;
            text-transform: uppercase;
            transition-property: opacity;

            @include hover {
              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &-title {
      color: #FFF;
      display: block;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: 2.6rem;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-bottom: 23px;
    }

    &-networks {
      align-items: center;
      display: flex;
      position: relative;
      margin-bottom: 33px;
      max-width: 107px;

      &::after {
        background-color: #D9D9D9;
        bottom: -16px;
        content: '';
        display: block;
        height: 1px;
        opacity: 0.15;
        position: absolute;
        width: 100%;
      }

      li {
        & + li {
          margin-left: 24px;
        }

        a {
          opacity: 0.6;
          transition-property: opacity;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &-phone {
      li {
        color: #fff;
        opacity: 0.6;

        & + li {
          margin-top: 11px;
        }
      }
    }

    p {
      color: #fff;
      opacity: 0.6;
    }
  }

  p,
  a {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.6rem;
  }
}

@include responsive(1279) {
  .footer {
    &-main {
      &-right {
        max-width: 600px;

        &::before {
          left: -44px;
        }
      }
    }
  }
}

@include responsive(991) {
  .footer {
    &-main {
      flex-direction: column;
      margin-inline: auto;

      &-left {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-inline: auto;
        max-width: 380px;

        p {
          text-align: center;
        }
      }

      &-right {
        max-width: unset;
        margin-top: 44px;

        &::before {
          display: none;
        }
      }
    }
  }
}

@include responsive(599) {
  .footer {
    &-main {
      &-right {
        align-items: center;
        flex-direction: column;
      }
    }

    &-bottom {
      align-items: center;
      flex-direction: column;

      &-right {
        margin-top: 4px;
      }
    }

    &-box {
      & + .footer-box {
        margin-top: 32px;
      }

      &-title {
        margin-bottom: 12px;
        text-align: center;
      }

      &-networks {
        justify-content: center;
      }

      p,
      a {
        display: block;
        text-align: center;
      }
    }
  }
}