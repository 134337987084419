.hero {
  &-small {
    height: 390px;
    overflow: hidden;
    padding-top: 140.5px;
    position: relative;

    &.bg-gradient-accent {
      background: linear-gradient(234deg, #902125 28.76%, #640C0F 75.78%, #640C0F 75.78%), linear-gradient(86deg, #0F0F0F 3.41%, #181818 89.93%, #1B1B1B 96.94%);
    }

    &.bg-gradient-dark {
      background: linear-gradient(86deg, #0F0F0F 3.41%, #181818 89.93%, #1B1B1B 96.94%);
    }

    .box-title {
      max-width: 480px;
      min-height: 149px;

      .sub-title,
      .title,
      p {
        color: #fff;
      }

      .sub-title {
        margin-bottom: 4px;
        font-weight: 400;
      }

      p {
        font-weight: 300;
        opacity: 0.8;
      }
    }
    
    .container-absolute {
      bottom: 0;
      height: 100%;
      left: 50%;
      pointer-events: none;
      position: absolute;
      transform: translateX(-50%);
      touch-action: none;
    }
  }

  &-illustration {
    position: relative;
    height: 100%;
    width: 100%;

    img {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &-line-horizontal,
  &-line-vertical {
    background-color: #D9D9D9;
    opacity: 0.23;
    position: absolute;
  }

  &-line-horizontal {
    height: 1px;
    left: 0;
    top: 78px;
    width: 100%;
  }

  &-line-vertical {
    height: 100%;
    bottom: 0;
    width: 1px;

    &.left {
      left: 7px;
    }
    
    &.right {
      right: 7px;
    }
  }
}

@include responsive(599) {
  .hero {
    &-small {
      height: 376px;
    }
  }
}