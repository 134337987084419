.box-input {
  position: relative;

  & + .box-input {
    margin-top: 18px;
  }

  .wpcf7-not-valid-tip {
    bottom: -25px;
    height: 18px;
    font-size: 12px;
    position: absolute;
  }
}

.wpcf7 {
  form {
    &.invalid,
    &.unaccepted,
    &.payment-required {
      .wpcf7-response-output {
        margin-inline: 0;
      }
    }
  }
}

input:not([type="checkbox"]):not([type="radio"]),
textarea {
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  font-size: 1.4rem;
  color: #0d0d0d;
  line-height: 1.8rem;
  outline: none;
  transition: 0.3s;

  &.invalid {
    border-color: rgb(229, 93, 93) !important;
    -webkit-box-shadow: 0 0 0 0.1875rem rgba(238, 58, 58, 0.2);
    box-shadow: 0 0 0 0.1875rem rgba(238, 58, 58, 0.2);
  }

  &:focus {
    border-color: #5dbcf2 !important;
    -webkit-box-shadow: 0 0 0 0.1875rem rgba(51, 180, 153, 0.2);
    box-shadow: 0 0 0 0.1875rem rgba(51, 180, 153, 0.2);
  }
  
  &:focus:-internal-autofill-selected,
  &:focus:-internal-autofill-previewed {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset,
      0 0 0 0.1875rem rgba(51, 180, 153, 0.2);
    box-shadow: 0 0 0px 1000px #fff inset, 0 0 0 0.1875rem rgba(51, 180, 153, 0.2);
  }

  &::placeholder {
    color: #4A4A4A;
    opacity: 0.5;
  }

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: #4A4A4A;
    opacity: 0.5;
  }
  
  &:-internal-autofill-selected,
  &:-internal-autofill-previewed {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
  }
}

label {
  color: #0D0D0D;
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.8rem;
  margin-bottom: 6px;
  opacity: 0.9;
}

input:not([type="checkbox"]):not([type="radio"]) {
  color: #0D0D0D;
  font-weight: 400;
  height: 44px;
  padding: 9px 12px;
}

textarea {
  display: block;
  font-family: inherit;
  height: 88px;
  resize: none;
  padding: 13px 12px 6px 12px;

  & + .wpcf7-not-valid-tip {
    bottom: -14px !important;
  }
}