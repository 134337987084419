.btn {
  border-radius: 2px;
  border-style: solid;
  border-width: 2px;
  font-family: $primary-font;
  font-size: 1.5rem;
  font-weight: 500;
  height: 51px;
  line-height: 23px;
  padding: 13px 36px 14px;
  position: relative;
  text-transform: uppercase;
  transition: 0.3s;

  &-primary {
    background-color: #121212;
    border-color: #121212;
    color: #fff;

    @include hover() {
      &:hover {
        background-color: #fff;
        color: #121212;

        svg {
          path {
            fill: #121212;
          }
        }
      }
    }
  }
  
  &-secondary {
    background-color: #fff;
    border-color: #fff;
    color: #121212;

    @include hover() {
      &:hover {
        background-color: #121212;
        color: #fff;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  &-accent {
    background-color: #902125;
    border-color: #902125;
    color: #fff;

    @include hover() {
      &:hover {
        background-color: #fff;
        color: #902125;

        svg {
          path {
            fill: #902125;
          }
        }
      }
    }
  }

  &-accent-reverse {
    background-color: #fff;
    border-color: #fff;
    color: #902125;

    @include hover() {
      &:hover {
        background-color: #902125;
        color: #fff;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  &-outline-primary {
    background-color: transparent;
    border-color: #fff;
    color: #fff;

    @include hover() {
      &:hover {
        background-color: #fff;
        color: #121212;

        svg {
          path {
            fill: #121212;
          }
        }
      }
    }  
  }

  &-outline-accent {
    background-color: transparent;
    border-color: #902125;
    color: #902125;

    @include hover() {
      &:hover {
        background-color: #902125;
        color: #fff;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  &-loader {
    &.loading {
      pointer-events: none;
      touch-action: none;

      span {
        transform: translateY(-194%);
      }
    }

    &-box {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 26px;
      overflow: hidden;
      position: relative;

      span {
        display: block;
        transform: translateY(0);
        transition: 0.3s transform;

        & + span {
          margin-top: 20px;
        }
      }
    }
  }

  &-video {
    background-color: transparent;
    transition: 0.3s opacity;

    &.play {
      height: 113px;
      width: 113px;
    }

    &.pause {
      opacity: 0;
      pointer-events: none;
      top: 280px;
      touch-action: none;

      .btn-video-icon {
        left: calc(50% + 0.5px);
      }
    }

    &-icon {
      left: calc(50% + 3px);
      position: absolute;
      top: calc(50% + 1px);
      transform: translate3d(-50%, -50%, 0);
    }
    
    div {
      position: relative;
    }

    span {
      bottom: -25px;
      color: #fff;
      display: block;
      font-size: 1.4rem;
      left: 50%;
      line-height: 2rem;
      min-width: max-content;
      position: absolute;
      text-align: center;
      transform: translateX(-50%);
      width: 100%;
    }
  }

  &-whatsapp {
    align-items: center; 
    background-color: #902125; 
    border-radius: 50%;
    bottom: 26px;
    display: flex;
    height: 50px;
    justify-content: center;
    position: fixed; 
    right: 26px;
    width: 50px;
    z-index: 9;
  
    &:not(.aos-animate) {
      opacity: 0;
    }
  
    &:not(.active) {
      opacity: 0 !important;
    }
    
    &.active,
    &.active.aos-animate {
      opacity: 1;
    }
  
    svg {
      left: 0.5px;
      position: relative;
    }
  }
  
  .icon-left,
  .icon-right {
    position: absolute;
  }

  .icon-right {
    top: 4px;
    right: 4px;
  }
  
  .icon-left {
    left: 4px;
    bottom: 4px;
  }
  
  @include hover() {
    &.hover-bg-transparent {
      &:hover {
        background-color: transparent;
      }
    }
  }
}

@include responsive(599) {
  .btn {
    &-whatsapp {
      bottom: 20px;
      right: 20px;
      height: 45px;
      width: 45px;
    }
  }
}