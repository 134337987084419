@keyframes aboutStampRotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loaderBtn {
  to {
    transform: rotate(1turn);
  }
}