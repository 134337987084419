.page-template-about {
  .hero-small {
    .box-title {
      max-width: 570px;
    }
  }
}

.about {
  &-hero {
    margin-bottom: 100px;

    .container-absolute {
      .hero-illustration {
        img {
          bottom: -240.75px;
          opacity: 0.23;
          right: 35.52px;
        }
      }
    }
  }
}

.objectives {
  margin-bottom: 160px;

  &-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &-text {
    max-width: 288px;
    width: 100%;
  }

  &-list {
    display: flex;
  }

  &-item {
    align-items: center;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    min-height: 282px;
    max-width: 280px;
    padding: 36px 18px;
    width: 100%;

    & + .objectives-item {
      margin-left: 22px;
    }

    &-img {
      align-items: center;
      display: flex;
      height: 60px;
      justify-content: center;
      min-height: 60px;
      width: 60px;
    }

    &.mission {
      background-color: #902125;

      h4,
      p {
        color: #fff;
      }

      h4 {
        font-weight: 400;
      }
    }

    &.vision {
      background-color: #EEEEEE;
    }

    &.values {
      background-color: #F1F1F1;
    }
    
    h4,
    p {
      text-align: center;
    }

    h4 {
      font-size: 1.6rem;
      font-family: $secondary-font;
      font-weight: 500;
      line-height: 2.2rem;
      margin-bottom: 20px;
      margin-top: 14px;
      text-transform: uppercase;
    }

    p {
      color: #6F6F6F;
      font-size: 1.5rem;
      line-height: 2.4rem;
    }
  }
}

.gallery {
  margin-bottom: 259px;

  &-img {
    display: grid;
    gap: 18px;
    grid-template-columns: 280px 280px 148px 148px;
    grid-template-rows: 157px 157px 157px;
    margin-left: -112px;
    margin-right: 32px;

    &-01,
    &-02 {
      grid-row: 3 span;
      height: 100%;
    }

    &-04,
    &-06 {
      height: 100%;
    }

    &-03 {
      grid-column: 2 span;
      height: 100%;
    }

    &-05 {
      grid-row: 2 span;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .container {
    align-items: center;
    display: flex;
  }
}

@include responsive(1279) {
  .about {
    &-hero {
      .container-absolute {
        .hero-illustration {
          img {
            opacity: 0.13;
            right: -8px;
          }
        }
      }
    }
  }

  .objectives {
    &-content {
      align-items: center;
    }

    &-text {
      margin-right: 32px;
      max-width: 218px;
    }
  }

  .gallery {
    &-img {
      grid-template-columns: 220px 220px 128px 128px;
      grid-template-rows: 135px 135px 134px;
    }
  }
}

@include responsive(991) {
  .objectives {
    &-content {
      flex-direction: column;
    }

    &-text {
      margin-bottom: 40px;
      margin-right: 0;
      max-width: 100%;
    }

    .box-title {
      align-items: center;
      display: flex;
      flex-direction: column;

      .title {
        text-align: center;

        br {
          display: none;
        }
      }
    }
  }

  .gallery {
    &-img {
      gap: 10px;
      grid-template-columns: 150px 150px 100px 100px;
      grid-template-rows: 135px 135px 134px;
    }
  }
}

@include responsive(767) {
  .about {
    &-hero {
      height: auto;
      padding-bottom: 42px;
    }
  }

  .objectives {
    &-list {
      flex-direction: column;
    }

    &-item {
      height: auto;
      max-width: 320px;
      padding: 36px 30px;

      & + .objectives-item {
        margin-top: 32px;
        margin-left: 0;
      }
    }
  }

  .gallery {
    &-img {
      grid-template-columns: 118px 118px 80px 80px;
    }
  }
}

@include responsive(599) {
  .about {
    &-hero {
      .container-absolute {
        .hero-illustration {
          img {
            bottom: unset;
            right: 0;
            top: 78px;
            width: calc(100% - 120px);
          }
        }
      }
    }
  }

  .gallery {
    &-img {
      grid-template-rows: 90px 100px 120px;
      margin-inline: auto;
    }

    &-text {
      margin-bottom: 40px;
      max-width: 100%;
      width: 100%;
  
      .box-title {
        align-items: center;
        display: flex;
        flex-direction: column;

        .title {
          text-align: center;

          br {
            display: none;
          }
        }
      }
    }


    .container {
      flex-direction: column-reverse;
    }
  }
}

@include responsive(479) {
  .gallery {
    &-img {
      grid-template-columns: 90px 90px 70px 70px;
      grid-template-rows: 80px 80px 110px;
    }
  }
}

@include responsive(374) {
  .gallery {
    &-img {
      grid-template-columns: 70px 70px 50px 50px;
      grid-template-rows: 30px 50px 80px;
    }
  }
}